import React from "react";
import Box from "@material-ui/core/Box";

function Credits() {
  return (
    <Box>
      <h2>Credits</h2>
      <p>thank you for support!</p>
      <Box
        m="20px 20px 40px"
        style={{
          fontSize: "14px",
          lineHeight: "22px",
          letterSpacing: "1px",
          textAlign: "left",
        }}
      >
        【ビート音源】
        <ul>
          <li>beat1 | 曲名 Homicide | 製作者 Johnathon M. Horner (Beat Mekanik) | ライセンス Creative Commons Attribution 4.0 International License</li>
          <li>beat2 | 曲名 HERIDAS | 製作者 Tatay Produciendo | ライセンス Creative Commons Attribution 4.0 International License</li>
          <li>beat3 | 曲名 DELINCUENTE | 製作者 Tatay Produciendo | ライセンス Creative Commons Attribution 4.0 International License</li>
          <li>beat4 | 曲名 NIKA | 製作者 vlad04.c | ライセンス Creative Commons Attribution 4.0 International License</li>
          <li>beat5 | 曲名 TRAPSTAR | 製作者 BXNFIRE | ライセンス Creative Commons Attribution 4.0 International License</li>
        </ul>
        <br />
        <br />
        <br />
        2025年2月5日更新　Acaperap | アカペラップ
      </Box>
    </Box>
  );
}

export default Credits;
